/*eslint-disable*/
import store from '@/store';
const tenantCode = store.getters.currentTenant.tenantCode;
// 表单配置信息
export const collapse = [
    {
        title: '基本信息',
        ref: 'basicForm',
        formName: 'basicInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '客户名称',
                prop: 'accountName',
                value: 'accountName',
                type: 'custom',
            },
            {
                label: '客户行业',
                prop: 'industry',
                value: 'industry',
                type: 'custom'
            },
            {
                label: '客户系',
                prop: 'customerGroup',
                value: 'customerGroup',
                type: 'custom',
                // optionsKey: 'SYS_CUST_RELATION_TYPE'
            },
            {
                label: '客户等级',
                prop: 'customerLevel',
                value: 'customerLevel',
                type: 'custom',
                // optionsKey: 'SYS_WM_CUSTOMER_LEVEL'
            },
            {
                label: '整体投标规模（万元）',
                prop: 'logisticsScale',
                value: 'logisticsScale',
                type: 'custom'
            },
            {
                label: '项目利润率(%)',
                prop: 'profitMargin',
                value: 'profitMargin',
                type: 'custom'
            },
            {
                label: '商机类型',
                prop: 'oppEntityType',
                value: 'oppEntityType',
                type: 'custom'
            },
            {
                label: '商机大类',
                prop: 'businessType',
                value: 'businessType',
                type: 'custom',
                disabled: true
            },
            {
                label: '业务类型',
                prop: 'servicesType',
                value: 'servicesType',
                type: 'custom'
            },
            {
                label: '运作情况',
                prop: 'operSituation',
                value: 'operSituation',
                type: 'custom'
            },
            {
                label: '计划运作日期',
                prop: 'planOperDate',
                value: 'planOperDate',
                type: 'custom'
            },
            {
                label: '期望上线日期',
                prop: 'expectLaunchDate',
                value: 'expectLaunchDate',
                type: 'custom'
            },
            {
                label: '需求描述',
                prop: 'operPlatformWh',
                value: 'operPlatformWh',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'custom',
                span: 24,
                rows: 3,
                content: '简单描述要对接的平台、仓库和对接的内容'
            }
        ]
    },
    {
        title: '对接信息',
        ref: 'dockingForm',
        formName: 'dockingInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '对接类型',
                prop: 'dockingType',
                value: 'dockingType',
                type: 'custom',
            },
            {
                label: '是否紧急对接需求',
                prop: 'urgentDocking',
                type: 'custom',
                disabled: false,
            },
            {
                label: '技术预排上线日期',
                prop: 'preschedulingDate',
                value: 'preschedulingDate',
                type: 'custom'
            },
            {
                label: '排期上线日期',
                prop: 'schedulingDate',
                value: 'schedulingDate',
                type: 'custom'
            },
            {
                label: '是否达坎级',
                prop: 'reachLevel',
                value: 'reachLevel',
                type: 'custom',
            },
            {
                label: '对接产品线',
                prop: 'productLine',
                value: 'productLine',
                type: 'custom',
            },
            {
                label: 'IT方案CF',
                prop: 'itProgramme',
                value: 'itProgramme',
                span: 12,
                type: 'custom',
                // optionsKey: 'SYS_CUST_RELATION_TYPE'
            },
            {
                label: '需求概述',
                prop: 'qdSummary',
                value: 'qdSummary',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                span: 24,
                rows: 3,
            },
            {
                label: '排期说明',
                prop: 'schedulingDescribe',
                value: 'schedulingDescribe',
                sourceType: 'input',
                inputType: 'textarea',
                type: 'input',
                span: 24,
                rows: 3,
            },

            {
                label: 'RDM主需求编码',
                prop: 'rdmCode',
                value: 'rdmCode',
                type: 'custom',
            },
            {
                label: '预估总人天',
                prop: 'estDays',
                value: 'estDays',
                type: 'custom',
            },
            {
                label: '预估内部人天',
                prop: 'estInsideDays',
                value: 'estInsideDays',
                type: 'custom',
            },
            {
                label: '预估外部人天',
                prop: 'estOutsideDays',
                value: 'estOutsideDays',
                type: 'custom',
            },
            {
                label: '预估总费用（元）',
                prop: 'estCost',
                value: 'estCost',
                type: 'custom',
            },
            {
                label: '预估其他费用（差旅等）（元）',
                prop: 'estOtherExpenses',
                value: 'estOtherExpenses',
                type: 'custom',
            },
            {
                label: '预估内部人天费用（元）',
                prop: 'estInsideExpenses',
                value: 'estInsideExpenses',
                type: 'custom',
            },
            {
                label: '预估外部人天费用（元）',
                prop: 'estOutsideExpenses',
                value: 'estOutsideExpenses',
                type: 'custom',
            }
        ]
    },
    {
        title: '系统信息',
        ref: 'systemForm',
        formName: 'systemInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '审批状态（MIP）',
                prop: 'mipStatus',
                value: 'mipStatus',
                type: 'custom',
                optionsKey: 'SYS_SU_MIP_AUDIT_STATUS'
            },
            {
                label: '申请人',
                prop: 'applicantName',
                value: 'applicant',
                type: 'custom'
            },
            {
                label: '申请日期',
                prop: 'applicationDate',
                value: 'applicationDate',
                type: 'custom'
            },
            {
                label: '流程类型（MIP）',
                prop: 'mipType',
                value: 'mipType',
                optionsKey: 'MIP_TYPE',
                type: 'custom'
            },
            {
                label: '当前流程节点',
                prop: 'mipNode',
                value: 'mipNode',
                type: 'custom'
            },
            {
                label: '当前处理人',
                prop: 'handlePerson',
                value: 'handlePerson',
                type: 'custom'
            },
            {
                label: '审批通过日期',
                prop: 'approvedDate',
                value: 'approvedDate',
                type: 'custom'
            },
            {
                label: 'MIP流程ID',
                prop: 'mipFlowId',
                value: 'mipFlowId',
                type: 'custom'
            }
        ]
    }
];
