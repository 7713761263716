<!-- eslint-disable max-lines -->
<template>
    <paper v-loading="btnloading" class="mip-iframe">
        <div class="application-detail"
            v-loading="detailLoading">
            <!-- 标题区域 -->
            <div class="header-box">
                <h4>IT资源对接申请</h4>
                <div class="header-info">
                    <div class="tips"></div>
                    <div class="apply-info">
                        <div>单据编码：{{ detailDataLocal.name }}</div>
                        <div>申请人：{{ detailDataLocal.applicantName }}</div>
                        <div>申请部门：{{ detailDataLocal.applicantDepartName }}</div>
                    </div>
                </div>
            </div>
            <!-- 表单区域 -->
            <collapse-infos
                ref="upholdHealder"
                :detailData="detailDataLocal"
                :collapse="COLLAPSE"
                :activeCollapses="activeCollapses"
                @change="handleInfosChange">
                <template v-slot:collapse-append="{form, itemSetting}">
                    <div v-if="itemSetting.prop === 'itProgramme'" class="link-box">
                        <a v-if="form[itemSetting.prop]" :href="form[itemSetting.prop]" class="flow-link" target="_blank">{{form[itemSetting.prop]}}</a>
                        <span v-else>--</span>
                    </div>
                    <div v-else-if="itemSetting.prop==='operPlatformWh'">
                        <el-input
                            type="textarea"
                            :rows="itemSetting.rows"
                            :placeholder="itemSetting.placeholder"
                            disabled
                            v-model="form[itemSetting.prop]">
                        </el-input>
                    </div>
                    <span v-else-if="!itemSetting.optionsKey" class="custom-span" :title="form[itemSetting.prop]">
                        {{(form[itemSetting.prop] || form[itemSetting.prop] === 0) ? form[itemSetting.prop] : '--'}}
                    </span>
                </template>
            </collapse-infos>
            <!-- iflow区域 -->
            <el-collapse
                v-if="detailDataLocal.mipFlowId"
                class="contract-collapse detail-more-collapse"
                value="mipInfo">
                <el-collapse-item title="准入审批" name="mipInfo">
                    <div class="header-operation">
                        <lots-button
                            size="mini"
                            type="primary"
                            @click="submitMip"
                            :loading="btnloading">
                            mip提交
                        </lots-button>
                    </div>
                    <iframe
                        v-if="!btnloading"
                        class="ifr"
                        id="myIframe"
                        ref="iframeRef"
                        :src="mipUrl" />
                </el-collapse-item>
            </el-collapse>
        </div>
    </paper>
</template>

<script>
import { ref, computed, getCurrentInstance, onMounted } from '@vue/composition-api';
import paper from '@/components/core/Paper.vue';
import lotsButton from '@/components/lots/lotsButton';
import CollapseInfos from '@/modules/mdm/views/contractModules/components/collapseInfos/CollapseInfos.vue';
import { approveProcess } from '@/modules/mdm/api/contractManage/detailMainApi.js';
import { queryResourceApplication } from '@mdm/api/opportunityModules/opportunityList.js';
import { contractWorkflowList } from '@mdm/api/contractManage/contractFootPageApi.js';
import { Message, MessageBox } from 'element-ui';
import store from '@/store/index';
import { collapse } from './collapseInfosConfig.js';
import _ from 'lodash';
import configurl from '@/config/user.env';
export default {
    name: 'resourceApplication',
    components: { paper, lotsButton, CollapseInfos },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const config = _.cloneDeep(collapse);
        const detailLoading = ref(false);
        const btnloading = ref(false);
        const code = ref('');
        const detailDataLocal = ref({
        });
        const activeCollapses = ref(['dockingInfo', 'basicInfo']);
        const mipUrl = ref('');
        const COLLAPSE = computed(() => { // 配置项动态计算
            config.forEach((v, i) => {
                // eslint-disable-next-line complexity
                v.formList.forEach((v2, i2) => {
                    if (v2.inputType !== 'textarea') {
                        v2.type = 'custom';
                    } else {
                        v2.disabled = true;
                    }
                });
            });
            return config;
        });
        const { userName, userCode } = store.getters.user;
        // eslint-disable-next-line complexity
        const _initData = async (loading = true) => {
            const { workflowId } = ctx.$route.query;
            let res = null;
            loading && (detailLoading.value = true);
            if (workflowId) {
                res = await queryResourceApplication({ workflowId: workflowId });
            }
            // 获取最新流程信息
            let mipType = '';
            if (loading) {
                const iflowRes = await contractWorkflowList({ contractCode: workflowId });
                if (+iflowRes.code === 0) {
                    if (iflowRes.data && iflowRes.data.list && iflowRes.data.list.length && iflowRes.data.list[0].mipId
                    ) {
                        mipType = iflowRes.data.list[0].mipType;
                        // 通过的，不做卡控
                    }
                }
            }
            detailLoading.value = false;
            if (res?.code === '0' && res.data) {
                handleInfosChange({ form: { ...res.data, mipType } });
            }
        };
        const handleInfosChange = (formObjData = {}) => { // 表单组件编辑回调，更新本地数据
            detailDataLocal.value = Object.assign({}, detailDataLocal.value, formObjData.form);
            setupContext.refs.upholdHealder.updataInitData(detailDataLocal.value);
            if (detailDataLocal.value.mipFlowId && !mipUrl.value) {
                handleCreate(); // 渲染iflow流程
            }
        };
        const TIME_OUT = 500;
        const handleCreate = () => {
            if (deepRefresh.value) { // 流程分支数据改变，需要整个页面刷新（iframe标签v-if刷新、reload刷新均失效的bug）
                deepRefresh.value = false;
                ctx.$router.go(0);
                return false;
            }
            mipUrl.value = '';
            setTimeout(() => {
                mipUrl.value = `${configurl.iflowUrl}?fdId=${detailDataLocal.value.mipFlowId}&fdTemplateKey=CRM_opportunity_resource&`
                    + `userCode=${userCode}&userName=${userName}`;
                createMetaNode();
                window.addEventListener('message', messageFunc, false);
            }, TIME_OUT);
        };
        const createMetaNode = () => {
            const mipmeta = document.getElementById('mipmeta');
            if (mipmeta) {
                // const iframe = document.getElementById('myIframe');
                // iframe.contentWindow.location.reload(true);
                return false;
            }
            const meta = document.createElement('meta');
            meta.httpEquiv = 'Content-Security-Policy';
            meta.content = 'upgrade-insecure-requests';
            meta.id = 'mipmeta';
            document.getElementsByTagName('head')[0].appendChild(meta);
        };
        const messageFunc = (event) => {
            if (event.data && event.data.data && event.data.success) {
                const { workflowId } = ctx.$route.query;
                const { origin, pathname } = window.location;
                const params = event.data.data || {};
                const { tenantCode } = store.getters.currentTenant;
                params.tenantCode = tenantCode;
                // params.currentApplicationCode = this.applicationCode;
                params.currentApplicationCode = 'APP201904230047';
                if (params.formParam) {
                    const { processParam } = params;
                    let isToOtherPersons = '';
                    if (processParam.operationType === 'circulate' && processParam.toOtherPersons) {
                        isToOtherPersons = processParam.toOtherPersons; // 被传阅的人
                    }
                    // eslint-disable-next-line max-len
                    params.formParam.fdUrl = `${origin}${pathname}#/resourceApplication?workflowId=${workflowId}${isToOtherPersons ? `&isToOtherPersons=${isToOtherPersons}` : ''}`; // mip代办跳转URL
                }
                btnloading.value = true;
                const tipsTimer = 1000;
                approveProcess(params).then(res => {
                    if (res && +res.code === 0) {
                        Message.success('提交成功');
                        setTimeout(() => {
                            ctx.$router.go(0);
                        }, tipsTimer);
                    } else {
                        Message.error(res.msg);
                    }
                }).finally(() => {
                    btnloading.value = false;
                });
            }
        };
        const deepRefresh = ref(false);

        // eslint-disable-next-line complexity
        const submitMip = async () => {
            // 第一次进入是从销售易过来的，那边的需要可能存在延迟，所以需要提交审批的时候再请求一遍
            if (detailDataLocal.value.latestSearch === '否') {
                await _initData(false);
            }
            if (detailDataLocal.value.latestSearch === '否') {
                MessageBox.confirm('当前页面已过期，请刷新页面！', '提示', {
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    type: 'warning'
                }).then(() => {
                    location.reload();
                }).catch(() => {
                    location.reload();
                });
                return;
            }
            const content = window;
            content.frames && content.frames.length && content.frames[0]
                && content.frames[0].postMessage('mbpm_getApprovalData', mipUrl.value);
            content.frames && content.frames.length && content.frames[1]
                && content.frames[1].postMessage('mbpm_getApprovalData', mipUrl.value); // 解决接入了智能客服后，content.frames[0]被占用了的问题
        };

        onMounted(() => {
            _initData();
        });
        return {
            config,
            COLLAPSE,
            detailLoading,
            btnloading,
            code,
            detailDataLocal,
            mipUrl,
            handleInfosChange,
            submitMip,
            activeCollapses
        };
    }
};
</script>
<style lang="less">
@scrollbarThumbColor: rgba(0,0,0, .2);
@scrollbarThumbColorHover: rgba(0,0,0, .4);

.mip-iframe {
    overflow: auto;
    .application-detail {
        .header-box {
            width: 100%;
            h4 {
                text-align: center;
                font-size: 20px;
                margin: 0;
                color: #606266;
            }
            .header-info {
                width: 100%;
                display: flex;
                font-size: 14px;
                border-bottom: 1px solid #dde0e6;
                margin-bottom: 10px;
                padding-bottom: 5px;
                .tips {
                    flex: 1;
                    color: red;
                }
                .apply-info {
                    line-height: 20px;
                    color: #606266;
                }
            }
        }
        .link-box{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .flow-link{
            color: #409eff;
        }
        .el-collapse-item__content {
            padding-bottom: 0;
        }
        .el-collapse-item__arrow {
            margin: 0 8px 0 5px;
        }
        .el-button.is-disabled.is-plain,
        .el-button.is-disabled.is-plain:focus,
        .el-button.is-disabled.is-plain:hover {
            border-color: #FFF;
        }
    }
    .ifr {
        width: 100%;
        height: 100vh;
        border: none;
    }
    .el-collapse-item__header {
        color: #606266;
        font-size: 14px;
        font-weight: 600;
    }

    .header-operation {
        margin-top: 10px;
    }
}
</style>
